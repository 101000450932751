import * as THREE from 'three';
import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls.js';
import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader.js';

//const monkeyUrl = new URL('../assets/doggo2.glb', import.meta.url);
//const monkeyUrl = new URL('../assets/caterpillar2.glb', import.meta.url);
//const monkeyUrl = new URL('../assets/birdsofparadise_root.glb', import.meta.url);
const monkeyUrl = new URL('../assets/bird_idle.glb', import.meta.url);
//const monkeyUrl = new URL('../assets/caterpillar.glb', import.meta.url);
//const monkeyUrl = new URL('../assets/shaking_egg.glb', import.meta.url);

const renderer = new THREE.WebGLRenderer();

renderer.setSize(window.innerWidth, window.innerHeight);

document.body.appendChild(renderer.domElement);

const scene = new THREE.Scene();

const camera = new THREE.PerspectiveCamera(
    1, //zoom
    window.innerWidth / window.innerHeight,
    0.1,
    1000
);

//background color
renderer.setClearColor(0xa1a1a1);

const orbit = new OrbitControls(camera, renderer.domElement);
camera.position.set(120, 0, 0); // x, top/bottom rotation, rotation
orbit.update();

//ground grid helper
/*const grid = new THREE.GridHelper(30, 30);
scene.add(grid);*/

//load 3D model
const assetLoader = new GLTFLoader();

let mixer;
assetLoader.load(monkeyUrl.href, function(gltf) {
    const model = gltf.scene;
    scene.add(model);
    mixer = new THREE.AnimationMixer(model);
    const clips = gltf.animations;
    model.translateY(-0.8);
    model.rotateY(1.6);

    // Play a certain animation
    // const clip = THREE.AnimationClip.findByName(clips, 'HeadAction');
    // const action = mixer.clipAction(clip);
    // action.play();

    // Play all animations at the same time
    clips.forEach(function(clip) {
        const action = mixer.clipAction(clip);
        action.play();
    });

}, undefined, function(error) {
    console.error(error);
});


//add light & shadow
const directionalLight = new THREE.DirectionalLight(0xffffff, 7);
scene.add(directionalLight);
directionalLight.position.set(45,45,45); //angle, distance, other angle 

const directionalLight2 = new THREE.DirectionalLight(0xffffff, 10);
scene.add(directionalLight2);
directionalLight2.position.set(-45,-45,-45);

//directional light/shadow helper
/*const dLightHelper = new THREE.DirectionalLightHelper(directionalLight, 1);
scene.add(dLightHelper);

const dLightHelper2 = new THREE.DirectionalLightHelper(directionalLight2, 1);
scene.add(dLightHelper2);*/


const clock = new THREE.Clock();
function animate() {
    if(mixer)
        mixer.update(clock.getDelta());
    renderer.render(scene, camera);
}

renderer.setAnimationLoop(animate);

window.addEventListener('resize', function() {
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();
    renderer.setSize(window.innerWidth, window.innerHeight);
});